<template>
  <RevenueIndex
    :endpoint="getEndpoint"
    :fstId="`vehicleRevenues_${getId}`"
    :qso="getEndpointOptions"
    :filterItems="filterOptions"
  />
</template>

<script>
import { useEndpoints } from '@/composables'
import RevenueIndex from '@/composites/transaction/revenue/index/Index.vue'

export default {
  name: 'ViewVehicleRevenues',

  components: {
    RevenueIndex,
  },
  data() {
    return {
      filterOptions: [
        {
          key: 'payment_type',
          type: 'checkbox',
          input: [
            { text: 'Trip', value: 'Trip' },
            { text: 'Card Trip', value: 'Trip With Card' },
            { text: 'Reservation', value: 'Reservation' },
            { text: 'Pass Trip', value: 'Pass Trip' },
            { text: 'Balance deduct by admin', value: 'Rider Balance Adjust' },
          ],

          title: 'Payment Type',
        },
        {
          key: 'payment_source',
          type: 'checkbox',
          input: [
            { text: 'Card', value: 'Card' },
            { text: 'eWallet', value: 'eWallet' },
            { text: 'Subscription Credit/Pass', value: 'Pass' },
          ],

          title: 'Payment Source',
        },
        {
          key: 'refund_status',
          type: 'checkbox',
          input: [{ text: 'Refunded', value: 'refunded' }],

          title: 'Refund Status',
        },
      ],
    }
  },

  computed: {
    getId() {
      return this.$route.params.id
    },
    getEndpoint() {
      return (
        useEndpoints.invoice.indexOfRevenue() + `bike=${this.$route.params.id}&`
      )
    },
    getEndpointOptions() {
      // don't prepent '?' @see useEndpoints.invoice.indexOfRevenue() for more info
      return { append: ``, prepend: `` }
    },
  },
}
</script>
